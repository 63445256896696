exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-academy-adp-jsx": () => import("./../../../src/pages/academy/adp.jsx" /* webpackChunkName: "component---src-pages-academy-adp-jsx" */),
  "component---src-pages-academy-amp-jsx": () => import("./../../../src/pages/academy/amp.jsx" /* webpackChunkName: "component---src-pages-academy-amp-jsx" */),
  "component---src-pages-academy-cplp-jsx": () => import("./../../../src/pages/academy/cplp.jsx" /* webpackChunkName: "component---src-pages-academy-cplp-jsx" */),
  "component---src-pages-academy-gma-jsx": () => import("./../../../src/pages/academy/gma.jsx" /* webpackChunkName: "component---src-pages-academy-gma-jsx" */),
  "component---src-pages-academy-gmcpi-jsx": () => import("./../../../src/pages/academy/gmcpi.jsx" /* webpackChunkName: "component---src-pages-academy-gmcpi-jsx" */),
  "component---src-pages-academy-jsx": () => import("./../../../src/pages/academy.jsx" /* webpackChunkName: "component---src-pages-academy-jsx" */),
  "component---src-pages-academy-sldp-jsx": () => import("./../../../src/pages/academy/sldp.jsx" /* webpackChunkName: "component---src-pages-academy-sldp-jsx" */),
  "component---src-pages-advisory-panel-jsx": () => import("./../../../src/pages/advisory-panel.jsx" /* webpackChunkName: "component---src-pages-advisory-panel-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-form-init-jsx": () => import("./../../../src/pages/form-init.jsx" /* webpackChunkName: "component---src-pages-form-init-jsx" */),
  "component---src-pages-gm-awards-jsx": () => import("./../../../src/pages/gm-awards.jsx" /* webpackChunkName: "component---src-pages-gm-awards-jsx" */),
  "component---src-pages-gm-awards-registration-jsx": () => import("./../../../src/pages/gm-awards-registration.jsx" /* webpackChunkName: "component---src-pages-gm-awards-registration-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-insights-ceo-jsx": () => import("./../../../src/pages/insights/ceo.jsx" /* webpackChunkName: "component---src-pages-insights-ceo-jsx" */),
  "component---src-pages-insights-chro-jsx": () => import("./../../../src/pages/insights/chro.jsx" /* webpackChunkName: "component---src-pages-insights-chro-jsx" */),
  "component---src-pages-insights-index-jsx": () => import("./../../../src/pages/insights/index.jsx" /* webpackChunkName: "component---src-pages-insights-index-jsx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-amit-malik-amit-malik-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/amit-malik/amit-malik.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-amit-malik-amit-malik-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-ankit-nagori-ankit-nagori-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/ankit-nagori/ankit-nagori.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-ankit-nagori-ankit-nagori-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-anupam-trehan-anupam-trehan-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/anupam-trehan/anupam-trehan.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-anupam-trehan-anupam-trehan-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-ashish-pipaliya-ashish-pipaliya-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/ashish-pipaliya/ashish-pipaliya.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-ashish-pipaliya-ashish-pipaliya-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-atul-edlabadkar-atul-edlabadkar-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/atul-edlabadkar/atul-edlabadkar.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-atul-edlabadkar-atul-edlabadkar-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-dalip-sehgal-dalip-sehgal-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/dalip-sehgal/dalip-sehgal.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-dalip-sehgal-dalip-sehgal-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-egbert-schram-egbert-schram-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/egbert-schram/egbert-schram.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-egbert-schram-egbert-schram-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-farah-malik-bhanji-farah-malik-bhanji-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/farah-malik-bhanji/farah-malik-bhanji.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-farah-malik-bhanji-farah-malik-bhanji-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-harjeet-khanduja-harjeet-khanduja-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/harjeet-khanduja/harjeet-khanduja.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-harjeet-khanduja-harjeet-khanduja-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-kapil-agrawal-kapil-agrawal-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/kapil-agrawal/kapil-agrawal.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-kapil-agrawal-kapil-agrawal-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-krishna-raghavan-krishna-raghavan-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/krishna-raghavan/krishna-raghavan.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-krishna-raghavan-krishna-raghavan-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-manish-kapoor-manish-kapoor-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/manish-kapoor/manish-kapoor.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-manish-kapoor-manish-kapoor-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-nr-mani-nr-mani-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/nr-mani/nr-mani.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-nr-mani-nr-mani-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-pankaj-arora-pankaj-arora-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/pankaj-arora/pankaj-arora.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-pankaj-arora-pankaj-arora-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-pankaj-poddar-pankaj-poddar-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/pankaj-poddar/pankaj-poddar.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-pankaj-poddar-pankaj-poddar-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-praveen-purohit-praveen-purohit-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/praveen-purohit/praveen-purohit.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-praveen-purohit-praveen-purohit-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-ravi-valecha-ravi-valecha-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/ravi-valecha/ravi-valecha.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-ravi-valecha-ravi-valecha-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-ruchira-bhardwaja-ruchira-bhardwaja-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/ruchira-bhardwaja/ruchira-bhardwaja.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-ruchira-bhardwaja-ruchira-bhardwaja-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-sandeep-bhargava-sandeep-bhargava-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/sandeep-bhargava/sandeep-bhargava.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-sandeep-bhargava-sandeep-bhargava-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-sandeep-dua-sandeep-dua-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/sandeep-dua/sandeep-dua.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-sandeep-dua-sandeep-dua-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-sanjay-kaul-sanjay-kaul-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/sanjay-kaul/sanjay-kaul.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-sanjay-kaul-sanjay-kaul-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-sharad-heda-sharad-heda-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/sharad-heda/sharad-heda.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-sharad-heda-sharad-heda-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-simin-askari-simin-askari-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/simin-askari/simin-askari.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-simin-askari-simin-askari-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-sudeep-dev-sudeep-dev-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/sudeep-dev/sudeep-dev.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-sudeep-dev-sudeep-dev-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-sunil-goyal-sunil-goyal-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/sunil-goyal/sunil-goyal.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-sunil-goyal-sunil-goyal-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-sushanth-tharappan-sushanth-tharappan-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/sushanth-tharappan/sushanth-tharappan.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-sushanth-tharappan-sushanth-tharappan-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-udbhav-ganjoo-udbhav-ganjoo-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/udbhav-ganjoo/udbhav-ganjoo.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-udbhav-ganjoo-udbhav-ganjoo-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-vikram-handa-vikram-handa-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/vikram-handa/vikram-handa.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-vikram-handa-vikram-handa-mdx" */),
  "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-vishak-kumar-vishak-kumar-mdx": () => import("./../../../src/pages/insights/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/src/insights/vishak-kumar/vishak-kumar.mdx" /* webpackChunkName: "component---src-pages-insights-mdx-frontmatter-slug-jsx-content-file-path-src-insights-vishak-kumar-vishak-kumar-mdx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-tapmi-jsx": () => import("./../../../src/pages/tapmi.jsx" /* webpackChunkName: "component---src-pages-tapmi-jsx" */),
  "component---src-pages-winners-jsx": () => import("./../../../src/pages/winners.jsx" /* webpackChunkName: "component---src-pages-winners-jsx" */)
}

